import React from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { Card, Carousel } from "../components/ui/apple-cards-carousel";
import {
  Skeleton1,
  Skeleton2,
  Skeleton3,
  Skeleton4,
  Skeleton5,
  Skeleton6,
  Skeleton7,
} from "../data/carsouselData";

interface DashboardProps {
  id: string;
}

const Dashboard: React.FC<DashboardProps> = ({ id }) => {
  const navigate = useNavigate();
  const { user, role, loading } = useUser();

  type Card = {
    id: number;
    content: React.ReactNode;
    className: string;
    thumbnail: string;
  };

  const data = [
    {
      category: "GCP Hub",
      title: "",
      src: "./1.png",
      content: <Skeleton1 />,
    },
    {
      category: "APIP Cycle",
      title: "",
      src: "./2.png",
      content: <Skeleton2 />,
    },
    {
      category: "Resource Hub",
      title: "",
      src: "./3.png",
      content: <Skeleton3 />,
    },

    {
      category: "MX Funnel",
      title: "",
      src: "./4.png",
      content: <Skeleton4 />,
    },
    {
      category: "MX Standards",
      title: "",
      src: "./5.png",
      content: <Skeleton5 />,
    },
    {
      category: "OD Model",
      title: "",
      src: "./6.png",
      content: <Skeleton6 />,
    },
    {
      category: "Sona Survey",
      title: "",
      src: "./7.png",
      content: <Skeleton7 />,
    },
  ];

  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !role) {
    return <div>You need to sign in to access the dashboard.</div>;
  }

  return (
    <div className="w-full h-full py-10" id={id}>
      <h2 className="max-w-7xl pl-4 mx-auto text-xl md:text-5xl font-bold text-neutral-800 dark:text-neutral-200 font-sans">
        Nexus Dashboard.
      </h2>
      <Carousel items={cards} />
    </div>
  );
};

export default Dashboard;
