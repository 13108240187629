import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../contexts/UserContext";
import { getDocs, collection, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase";

interface User {
  id: string;
  email: string;
  role: string;
}

interface RoutePermission {
  _id: string;
  route: string;
  accessRoles: string[];
}

const AdminPanel: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [routePermissions, setRoutePermissions] = useState<RoutePermission[]>(
    []
  );
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [filteredRoutePermissions, setFilteredRoutePermissions] = useState<
    RoutePermission[]
  >([]);
  const [selectedRoles, setSelectedRoles] = useState<{
    [key: string]: string[];
  }>({});
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { role } = useUser();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const usersPerPage = 10;

  useEffect(() => {
    const fetchUsersAndPermissions = async () => {
      try {
        const usersCollection = collection(db, "users");
        const userDocs = await getDocs(usersCollection);
        const usersList = userDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as User[];

        const routesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/route-permissions`
        );
        setUsers(usersList);
        setFilteredUsers(usersList);
        setRoutePermissions(routesResponse.data);
        setFilteredRoutePermissions(routesResponse.data);

        const roles = routesResponse.data.reduce(
          (acc: { [key: string]: string[] }, route: RoutePermission) => {
            acc[route._id] = route.accessRoles;
            return acc;
          },
          {}
        );
        setSelectedRoles(roles);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (role === "admin") {
      fetchUsersAndPermissions();
    }
  }, [role]);

  useEffect(() => {
    const filteredUsers = users.filter((user) => {
      if (!user.email) return false;
      return user.email.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredUsers(filteredUsers);

    const filteredRoutes = routePermissions.filter((route) =>
      route.route.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRoutePermissions(filteredRoutes);
  }, [searchTerm, users, routePermissions]);

  const handleChangeUserRole = async (userId: string, newRole: string) => {
    try {
      const userDoc = doc(db, "users", userId);
      await updateDoc(userDoc, { role: newRole });
      setUsers(
        users.map((user) =>
          user.id === userId ? { ...user, role: newRole } : user
        )
      );
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  const handleRoleChange = (id: string, roles: string[]) => {
    setSelectedRoles((prevSelectedRoles) => ({
      ...prevSelectedRoles,
      [id]: roles,
    }));
  };

  const handleSubmit = async (id: string) => {
    try {
      const newRoles = selectedRoles[id];
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/route-permissions/${id}`,
        { accessRoles: newRoles }
      );
      setRoutePermissions(
        routePermissions.map((route) =>
          route._id === id ? response.data : route
        )
      );
      setSuccessMessage("Route permissions updated successfully!");
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error) {
      console.error("Error updating route permission:", error);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredUsers.length / usersPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b text-black p-6">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8">Admin Panel</h1>
        <input
          type="text"
          placeholder="Search for users or routes..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border-2 border-gray-300 text-gray-800 mb-6"
        />

        <h2 className="text-2xl font-semibold mb-4">Manage Users</h2>
        <table className="w-full bg-white rounded-md shadow-md text-gray-800">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-left">Email</th>
              <th className="px-4 py-2 text-left">Role</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user) => (
              <tr key={user.id} className="border-t">
                <td className="px-4 py-2">{user.email}</td>
                <td className="px-4 py-2">
                  <select
                    value={user.role}
                    onChange={(e) =>
                      handleChangeUserRole(user.id, e.target.value)
                    }
                    className="w-full p-2 rounded-md border border-gray-300"
                  >
                    <option value="user">User</option>
                    <option value="tm">TM</option>
                    <option value="tl">TL</option>
                    <option value="lcvp">LCVP</option>
                    <option value="mcvp">MCVP</option>
                    <option value="admin">Admin</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination Controls */}
        <div className="mt-6 flex justify-between items-center">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md font-medium ${
              currentPage === 1
                ? "bg-gray-500 cursor-not-allowed text-white"
                : "bg-blue-500 hover:bg-blue-600 text-white"
            }`}
          >
            Previous
          </button>
          <span className="text-lg font-medium">
            Page {currentPage} of{" "}
            {Math.ceil(filteredUsers.length / usersPerPage)}
          </span>
          <button
            onClick={handleNextPage}
            disabled={
              currentPage === Math.ceil(filteredUsers.length / usersPerPage)
            }
            className={`px-4 py-2 rounded-md font-medium ${
              currentPage === Math.ceil(filteredUsers.length / usersPerPage)
                ? "bg-gray-500 cursor-not-allowed text-white"
                : "bg-blue-500 hover:bg-blue-600 text-white"
            }`}
          >
            Next
          </button>
        </div>
      </div>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        Manage Route Permissions
      </h2>
      {successMessage && (
        <div className="p-4 bg-green-500 text-white rounded-md mb-4">
          {successMessage}
        </div>
      )}
      <table className="w-full bg-white rounded-md shadow-md text-gray-800">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-left">Route</th>
            <th className="px-4 py-2 text-left">Access Roles</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredRoutePermissions.map((route) => (
            <tr key={route._id} className="border-t">
              <td className="px-4 py-2">{route.route}</td>
              <td className="px-4 py-2">
                <select
                  multiple
                  value={selectedRoles[route._id] || []}
                  onChange={(e) =>
                    handleRoleChange(
                      route._id,
                      Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      )
                    )
                  }
                  className="w-full p-2 rounded-md border border-gray-300"
                >
                  <option value="user">User</option>
                  <option value="tm">TM</option>
                  <option value="tl">TL</option>
                  <option value="lcvp">LCVP</option>
                  <option value="mcvp">MCVP</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
              <td className="px-4 py-2 text-center">
                <button
                  onClick={() => handleSubmit(route._id)}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                >
                  Save
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPanel;
