import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tabs } from './ui/tabs'; // Assuming Tabs component is in the same directory

interface Tab {
  title: string;
  value: string;
  content: React.ReactNode;
}

interface FunnelData {
  lc: string;
  term: string;
  role: string;
  portfolio?: string;
  metrics: {
    Leads: number;
    Applicants: number;
    Accepted: number;
    Approved: number;
    Realised: number;
    Finished: number;
    Completes: number;
    Advanced: number;
    Alumni: number;
  };
}

const MXFunnel: React.FC = () => {
  const [data, setData] = useState<FunnelData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const lcOptions = [
    "Multan",
    "LUMS",
    "IBA",
    "Islamabad",
    "NUST",
    "South Karachi",
    "Karachi",
    "Hyderabad",
    "Lahore",
    "Faisalabad",
    "Giki",
    "MC", // Added MC option
  ];

  const terms = ["short", "mid", "long"];
  const portfolios = [
    "MKT & PR",
    "iCX",
    "oGV",
    "oGT",
    "EwA",
    "BD",
    "MXP",
    "FnL",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<FunnelData[]>(`${process.env.REACT_APP_API_URL}/api/mxfunnel`);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div className="text-center text-xl py-10">Loading...</div>;
  if (error) return <div className="text-center text-red-600 py-10">Error: {error}</div>;

  const getSummary = (filteredData: FunnelData[], title: string) => {
    const summary = filteredData.reduce(
      (acc, funnel) => {
        Object.keys(funnel.metrics).forEach((key) => {
          acc[key as keyof FunnelData['metrics']] += funnel.metrics[key as keyof FunnelData['metrics']];
        });
        return acc;
      },
      {
        Leads: 0,
        Applicants: 0,
        Accepted: 0,
        Approved: 0,
        Realised: 0,
        Finished: 0,
        Completes: 0,
        Advanced: 0,
        Alumni: 0,
      }
    );

    return (
      <div>
        <h3 className="text-2xl font-semibold mb-6 text-center text-indigo-600">{title}</h3>
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(summary).map(([key, value]) => (
            <div key={key} className="p-4 bg-white rounded-lg shadow-md text-center">
              <p className="font-semibold text-gray-700">{key}</p>
              <p className="text-xl text-indigo-500">{value}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTabContent = (filteredData: FunnelData[]) => {
    return (
      <div>
        {filteredData.map((funnel, index) => (
          <div key={index} className="mb-6 p-6 bg-white rounded-lg shadow-md">
            <h4 className="text-lg font-bold mb-4 text-indigo-700">{funnel.role}</h4>
            {funnel.portfolio && <p className="text-gray-600 mb-4">Portfolio: {funnel.portfolio}</p>}
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(funnel.metrics).map(([key, value]) => (
                <div key={key} className="p-3 bg-gray-100 rounded text-center">
                  <p className="font-semibold text-gray-700">{key}</p>
                  <p className="text-lg text-indigo-500">{value}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // National summary for mid-term data only
  const nationalSummary = getSummary(
    data.filter((funnel) => funnel.term === "mid"),
    "National Summary for AIESEC in Pakistan (Mid Term Only)"
  );

  const tabs = lcOptions.reduce<{ [key: string]: Tab[] }>((acc, lc) => {
    const lcData = data.filter((funnel) => funnel.lc === lc);

    acc[lc] = [
      {
        title: "Overall Summary",
        value: "overall-summary",
        content: getSummary(lcData, `Summary for ${lc}`),
      },
      ...terms.map((term) => ({
        title: `${term.charAt(0).toUpperCase() + term.slice(1)} Term`,
        value: term,
        content: renderTabContent(
          lcData.filter((funnel) => funnel.term === term)
        ),
      })),
      ...(lc === "MC"
        ? [
            {
              title: "Mid Term - NST",
              value: "mid-nst",
              content: (
                <Tabs
                  tabs={portfolios.map((portfolio) => ({
                    title: portfolio,
                    value: portfolio,
                    content: renderTabContent(
                      lcData.filter(
                        (funnel) =>
                          funnel.term === "mid" &&
                          funnel.role === "NST" &&
                          funnel.portfolio === portfolio
                      )
                    ),
                  }))}
                />
              ),
            },
          ]
        : [
            {
              title: "Mid Term - Team Leaders",
              value: "mid-team-leaders",
              content: (
                <Tabs
                  tabs={portfolios.map((portfolio) => ({
                    title: portfolio,
                    value: portfolio,
                    content: renderTabContent(
                      lcData.filter(
                        (funnel) =>
                          funnel.term === "mid" &&
                          funnel.role === "Team Leader" &&
                          funnel.portfolio === portfolio
                      )
                    ),
                  }))}
                />
              ),
            },
          ]),
    ];

    return acc;
  }, {});

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-extrabold mb-8 text-center text-indigo-700">MX Funnel Results</h1>
      <Tabs
        tabs={[
          {
            title: "National Summary",
            value: "national-summary",
            content: nationalSummary,
          },
          ...Object.entries(tabs).map(([lc, termTabs]) => ({
            title: lc,
            value: lc,
            content: <Tabs tabs={termTabs} />,
          })),
        ]}
      />
    </div>
  );
};

export default MXFunnel;
