import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (error: any) {
      console.error("Error sending password reset email:", error);
      setMessage("Failed to send password reset email: " + error.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-900 to-nexusPurple text-white">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-gray-800">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-6">
          Reset Password
        </h1>
        <p className="text-center text-sm text-gray-600 mb-8">
          Enter your email address, and we’ll send you instructions to reset
          your password.
        </p>
        <form onSubmit={handlePasswordReset} className="space-y-6">
          {/* Email Input */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                className="block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 placeholder-gray-400 shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                type="email"
                placeholder="you@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-purple-600 hover:bg-purple-700 text-black font-semibold py-3 px-4 rounded-lg shadow-md transition-transform transform hover:scale-105"
          >
            Send Password Reset Email
          </button>
        </form>
        {/* Message */}
        {message && (
          <p
            className={`mt-5 font-semibold text-center ${
              message.includes("failed")
                ? "text-red-500"
                : "text-green-500"
            }`}
          >
            {message}
          </p>
        )}
        {/* Back to Sign-In */}
        <div className="mt-6 text-center">
          <button
            onClick={() => navigate("/signin")}
            className="text-sm text-purple-600 hover:underline"
          >
            Back to Sign-In
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
