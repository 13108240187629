import React, { useState, useEffect } from "react";
import axios from "axios";

interface GCP {
  _id: string;
  title: string;
  name: string;
  email: string;
  portfolio: string;
  category: string;
  localCommittee: string;
  gcpDetails: string;
  gcpLink: string;
  image: string; // Google Drive direct link
  status: string;
  createdAt: string;
}

const GCPApproval: React.FC = () => {
  const [gcps, setGcps] = useState<GCP[]>([]); // GCP data
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const limit = 8; // Items per page

  useEffect(() => {
    getAllGCPs(currentPage);
  }, [currentPage]);

  const getAllGCPs = async (page: number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/gcp/`,
        {
          params: { page, limit }, // Pass page and limit as query parameters
        }
      );
      setGcps(response.data.gcps);
      setTotalPages(response.data.totalPages); // Set total pages from response
    } catch (err) {
      console.error("Error fetching GCP Approval:", err);
      setError("Failed to load GCPs. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b text-black px-6 py-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8">GCP Approval</h1>
        {loading && (
          <p className="text-center text-lg font-medium">Loading GCPs...</p>
        )}
        {error && (
          <p className="text-center text-red-500 font-medium">{error}</p>
        )}
        {!loading && !error && (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {gcps.map((gcp) => (
                <GCPCard key={gcp._id} gcp={gcp} />
              ))}
            </div>

            {/* Pagination Controls */}
            <div className="mt-6 flex justify-center items-center space-x-4">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className={`px-4 py-2 rounded-md font-medium ${
                  currentPage === 1
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                } text-white`}
              >
                Previous
              </button>
              <span className="text-lg font-medium">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 rounded-md font-medium ${
                  currentPage === totalPages
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                } text-white`}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

interface GCPCardProps {
  gcp: GCP;
}

const GCPCard: React.FC<GCPCardProps> = ({ gcp }) => {
  const [status, setStatus] = useState(gcp.status);
  // Handlers for Accept and Reject actions
  const handleAccept = async () => {
    console.log(`GCP with ID ${gcp._id} approved.`);
    try {
      console.log(gcp._id);
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/gcp/${gcp._id}/approve`
      );
      // Update the status locally to reflect the change
      setStatus("approved");
    } catch (err) {
      console.error("Error approving GCP:", err);
      alert("Failed to approve GCP. Please try again later.");
    }
  };

  const handleReject = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/gcp/${gcp._id}/reject`
      );
      console.log(`GCP with ID ${gcp._id} rejected.`);
      // Update the status locally to reflect the change
      setStatus("rejected");
    } catch (err) {
      console.error("Error rejecting GCP:", err);
      alert("Failed to reject GCP. Please try again later.");
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 text-gray-800">
      {/* Image */}
      <div className="mb-4">
        <img
          src={gcp.image}
          alt={gcp.title}
          className="w-full h-40 object-cover rounded-md"
        />
      </div>

      {/* Title */}
      <h2 className="text-xl font-bold mb-2 text-gray-900">{gcp.title}</h2>

      {/* Details */}
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Name:</span> {gcp.name}
      </p>
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Email:</span> {gcp.email}
      </p>
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Portfolio:</span> {gcp.portfolio}
      </p>
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Category:</span> {gcp.category}
      </p>
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Local Committee:</span>{" "}
        {gcp.localCommittee}
      </p>
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Details:</span> {gcp.gcpDetails}
      </p>
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Link:</span>{" "}
        <a
          href={gcp.gcpLink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          View GCP
        </a>
      </p>
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Status:</span>{" "}
        <span
          className={`px-2 py-1 rounded-full text-xs font-semibold ${
            status === "approved"
              ? "bg-green-100 text-green-800"
              : status === "rejected"
              ? "bg-red-100 text-red-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          {status}
        </span>
      </p>
      <p className="text-sm text-gray-600">
        <span className="font-medium">Created At:</span>{" "}
        {new Date(gcp.createdAt).toLocaleDateString()}
      </p>

      {/* Accept and Reject Buttons */}
      <div className="mt-6 flex justify-between">
        <button
          onClick={handleAccept}
          className="bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-md shadow-md transition duration-200"
        >
          Accept
        </button>
        <button
          onClick={handleReject}
          className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md shadow-md transition duration-200"
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default GCPApproval;
