import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../contexts/UserContext";
import { useMediaQuery } from "react-responsive";
import { Tabs } from "../components/ui/tabs";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; 
import "../styles/SonaSurvey.css"; 

interface Survey {
  _id: string;
  lc: string;
  portfolios: string[];
  progress: Record<string, boolean>;
}

const SonaSurvey: React.FC = () => {
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<Survey | null>(null);
  const { role } = useUser();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const response = await axios.get(
         `${process.env.REACT_APP_API_URL}/api/survey`
        );
        console.log("Fetched surveys:", response.data);
        setSurveys(response.data);
      } catch (error) {
        console.error("Error fetching surveys:", error);
      }
    };

    fetchSurveys();
  }, []);

  const handleUpdateProgress = async (
    surveyId: string,
    portfolio: string,
    newProgress: boolean
  ) => {
    try {
      const survey = surveys.find((s) => s._id === surveyId);
      if (survey) {
        const updatedProgress = {
          ...survey.progress,
          [portfolio]: newProgress,
        };
        console.log("Updated progress:", updatedProgress);
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/survey/${surveyId}`,
          { progress: updatedProgress }
        );
        setSurveys(
          surveys.map((s) => (s._id === surveyId ? response.data : s))
        );
      }
    } catch (error) {
      console.error("Error updating survey progress:", error);
    }
  };

  const handleSurveyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSurveyId = event.target.value;
    const survey = surveys.find((s) => s._id === selectedSurveyId);
    setSelectedSurvey(survey || null);
  };

  const countCheckedBoxes = (progress: Record<string, boolean>): number => {
    const totalBoxes = Object.keys(progress).length;
    const checked = Object.values(progress).filter((checked) => checked).length;
    const percentage = (checked / totalBoxes) * 100;
    return percentage;
  };

  // Calculate the overview data
  const overviewData = () => {
    let totalChecklists = 0;
    let totalCompleted = 0;

    surveys.forEach((survey) => {
      totalChecklists += Object.keys(survey.progress).length;
      totalCompleted += Object.values(survey.progress).filter(
        (checked) => checked
      ).length;
    });

    const overviewProgress = (totalCompleted / totalChecklists) * 100;

    return { totalChecklists, totalCompleted, overviewProgress };
  };

  const overview = overviewData();

  const tabs = [
    {
      title: "Overview",
      value: "overview",
      content: (
        
        <div className="w-full p-6 bg-gradient-to-r from-white via-[#ad4ef8] to-[#a42df8] rounded-lg shadow-md">
          <h2 className="text-6xl font-bold mb-4 text-gray-800 text-center font-oswald pb-10">
            Survey Overview
          </h2>
          <div className="flex justify-center mb-4">
            <div className="w-40 h-40">
              <CircularProgressbar
                value={overview.overviewProgress}
                text={`${overview.overviewProgress.toFixed(2)}%`}
                styles={buildStyles({
                  textSize: "16px",
                  pathColor: `#6B46C1`, 
                  textColor: "#FFFFFF", 
                  trailColor: "#FFFFFF",
                })}
              />
            </div>
          </div>
          <p className="text-center text-black mb-4 font-bold pt-5 pb-3">
            Completed Surveys: {overview.totalCompleted}
          </p>
          <p className="text-center text-black mb-4 font-bold pb-3">
            Total Surveys: {overview.totalChecklists}
          </p>

          <p className="text-center text-black mb-4 font-bold pb-10">
            {`Completion Rate: ${overview.overviewProgress.toFixed(2)}%`}
          </p>
        </div>
      ),
    },
    ...surveys.map((survey) => {
      const surveyProgress = countCheckedBoxes(survey.progress);

      return {
        title: survey.lc,
        value: survey._id,
        content: (
          <div className="w-full p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-4 text-gray-800 text-center font-oswald">
               AIESEC in {survey.lc}
            </h2>
            <div className="flex justify-center mb-4">
              <div className="w-24 h-24">
                <CircularProgressbar
                  value={surveyProgress}
                  text={`${surveyProgress.toFixed(2)}%`}
                  styles={buildStyles({
                    textSize: "16px",
                    pathColor: `#6B46C1`, // Purple color for the progress path
                    textColor: "#6B46C1", // Purple color for the text
                    trailColor: "#d6d6d6",
                  })}
                />
              </div>
            </div>
            <p className="text-center text-gray-600 mb-4">{`Completion Rate: ${surveyProgress.toFixed(
              2
            )}%`}</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {survey.portfolios.map((portfolio) => (
                <div key={portfolio} className="mb-4">
                  <label className="block font-medium text-gray-700 text-lg">
                    {portfolio}
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        checked={!!survey.progress[portfolio]}
                        onChange={(e) =>
                          handleUpdateProgress(
                            survey._id,
                            portfolio,
                            e.target.checked
                          )
                        }
                        disabled={role !== "admin" && role !== "mcvp"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ),
      };
    }),
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10 px-4">
      <h1 className="text-4xl font-extrabold mb-6 text-gray-800 text-center font-oswald">
        SONA Survey Progress Dashboard
      </h1>
      {surveys.length === 0 ? (
        <p className="text-gray-600">No surveys found.</p>
      ) : (
        <div className="w-full max-w-5xl">
          {isMobile ? (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Select LC
              </label>
              <select
                className="w-full p-2 border border-gray-300 rounded-md"
                onChange={handleSurveyChange}
              >
                <option value="">Select LC</option>
                {surveys.map((survey) => (
                  <option key={survey._id} value={survey._id}>
                    {survey.lc}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <Tabs tabs={tabs} />
          )}
          {selectedSurvey && (
            <div className="w-full p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4 text-gray-800 text-center font-oswald">
                {selectedSurvey.lc}
              </h2>
              <div className="flex justify-center mb-4">
                <div className="w-24 h-24">
                  <CircularProgressbar
                    value={countCheckedBoxes(selectedSurvey.progress)}
                    text={`${countCheckedBoxes(selectedSurvey.progress).toFixed(
                      2
                    )}%`}
                    styles={buildStyles({
                      textSize: "16px",
                      pathColor: `#6B46C1`, // Purple color for the progress path
                      textColor: "#6B46C1", // Purple color for the text
                      trailColor: "#d6d6d6",
                    })}
                  />
                </div>
              </div>
              <p className="text-center text-gray-600 mb-4">{`${countCheckedBoxes(
                selectedSurvey.progress
              ).toFixed(2)}% Completed`}</p>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-auto">
                {selectedSurvey.portfolios.map((portfolio) => (
                  <div key={portfolio} className="mb-4">
                    <label className="block font-medium text-gray-700">
                      {portfolio}
                    </label>
                    <div className="flex items-center mt-2 justify-center pl-5">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          checked={!!selectedSurvey.progress[portfolio]}
                          onChange={(e) =>
                            handleUpdateProgress(
                              selectedSurvey._id,
                              portfolio,
                              e.target.checked
                            )
                          }
                          disabled={role !== "admin"}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SonaSurvey;



