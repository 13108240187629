import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import axios from "axios";
import { Button } from "./moving-border"; // Assume this is a custom button component

const MultiStepChecklistForm = ({ userId }: { userId: string }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState({
    generalDetail: {
      firstName: "",
      lastName: "",
      portfolio: "",
      lcName: "",
    },
    jobDesign: {
      jobDescription: false,
      jobKpis: false, // Changed to a string
      personalDevelopment: "",
      pdpSeptember: false,
      pdpDecember: false,
    },
    jobSupport: {
      transitionDelivery: false,
      roleStrengths: false,
      transitionDocument: "", // Changed to a string
      onboardingLCVP: false,
      onboardingMembers: false,
      onboardingDocument: "", // Changed to a string
      toolsLCVP: false,
      toolsTLD: false,
      downscaleTools: false,
      toolsDocument: "", // Changed to a string
    },
    community: {
      internalComms: false,
      portfolioComms: false,
      nationalUpdates: false,
      facebookGroup: false,
      hrPolicy: false,
      codeOfEthics: false,
      hrEthicsProof: "", // Changed to a string
    },
    communityEngagement: {
      functionalEmail: false,
      emailSignature: false,
      functionalEXPA: false,
      accountabilityRoutines: false,
      gbEngagementEvents: false,
      portfolioEngagementEvents: false,
      quarterlyAppraisals: false,
      quarterlyRnRs: false,
    },
    environmentWellbeing: {
      physicalWorkspace: false,
      virtualWorkspace: false,
      workspacePlans: "", // Changed to a string
      o2oRoutine: false,
      o2oMembers: false,
      tlCoaching: false,
      mentalWellbeing: false,
    },
    teamPractice: {
      teamVision: false,
      teamRules: false,
      teamGoals: false,
      teamKPIs: false,
      gtkeoMeet: false,
      teamDays: false,
      engagementRoutines: false,
      engagementRoutinesShare: "", // Changed to a string
      teamPerformanceEvaluation: false,
      performanceEvaluationSystem: "", // Changed to a string
      quarterlyImpactReports: "", // Changed to a string
      leadSpaces: false,
      personalFunctionalDevelopment: false,
      functionalSpaces: false,
      gbDevelopmentSpaces: false,
      teamClosingMeet: false,
      portfolioClosingMeet: false,
      semesterImpactReport: "", // Changed to a string
    },
  });

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/checklist/${userId}`
        );
        if (response.data) {
          setFormValues(response.data);
        }
      } catch (error) {
        console.error("Error fetching saved form data:", error);
      }
    };

    fetchFormData();
  }, [userId]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleChange =
    (section: keyof typeof formValues, item: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [section]: {
          ...prevFormValues[section],
          [item]:
            e.target.type === "checkbox"
              ? (e.target as HTMLInputElement).checked
              : e.target.value,
        },
      }));
    };

  const handleSaveProgress = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/checklist/submit`, {
        userId,
        formData: formValues,
      });
      alert("Progress saved successfully");
    } catch (error) {
      console.error("Error saving progress:", error);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await axios.post("http:/localhost:5000/api/checklist/submit", {
        userId,
        formData: formValues,
      });
      alert("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4 sm:w-full">
            <h2 className="text-3xl font-semibold mb-6 text-center">
              General Details
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  First Name
                </label>
                <input
                  type="text"
                  value={formValues.generalDetail.firstName}
                  onChange={handleChange("generalDetail", "firstName")}
                  className="block w-full md:w-[60%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Last Name
                </label>
                <input
                  type="text"
                  value={formValues.generalDetail.lastName}
                  onChange={handleChange("generalDetail", "lastName")}
                  className="block w-full md:w-[60%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Portfolio
                </label>
                <select
                  value={formValues.generalDetail.portfolio}
                  onChange={handleChange("generalDetail", "portfolio")}
                  className="block w-full md:w-[60%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="" disabled>
                    Select Portfolio
                  </option>
                  <option value="MKT & PR">MKT & PR</option>
                  <option value="iCX">iCX</option>
                  <option value="oGV">oGV</option>
                  <option value="oGT">oGT</option>
                  <option value="EwA">EwA</option>
                  <option value="BD">BD</option>
                  <option value="MXP">MXP</option>
                  <option value="FnL">FnL</option>
                </select>
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  LC Name
                </label>
                <select
                  value={formValues.generalDetail.lcName}
                  onChange={handleChange("generalDetail", "lcName")}
                  className="block w-full md:w-[60%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="" disabled>
                    Select LC Name
                  </option>
                  <option value="LUMS">LUMS</option>
                  <option value="Multan">Multan</option>
                  <option value="IBA">IBA</option>
                  <option value="Islamabad">Islamabad</option>
                  <option value="NUST">NUST</option>
                  <option value="South Karachi">South Karachi</option>
                  <option value="Karachi">Karachi</option>
                  <option value="Hyderabad">Hyderabad</option>
                  <option value="Lahore">Lahore</option>
                  <option value="Faisalabad">Faisalabad</option>
                  <option value="Giki">Giki</option>
                </select>
              </div>
            </div>
            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={nextStep}
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Next
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Job Design
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you thoroughly been explained what your Job is about?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobDesign.jobDescription}
                  onChange={handleChange("jobDesign", "jobDescription")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you been thoroughly explained what your KPIs & MoS are?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobDesign.jobKpis}
                  onChange={handleChange("jobDesign", "jobKpis")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you filled your PDP ?
                </label>
                <input
                  type="text"
                  value={formValues.jobDesign.personalDevelopment}
                  onChange={handleChange("jobDesign", "personalDevelopment")}
                  placeholder="Enter Personal Development link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you revisited your PDP with your LCVP during September?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobDesign.pdpSeptember}
                  onChange={handleChange("jobDesign", "pdpSeptember")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you revisited your PDP with your LCVP during December?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobDesign.pdpDecember}
                  onChange={handleChange("jobDesign", "pdpDecember")}
                  className="ml-2"
                />
              </div>
            </div>
            <div className="flex justify-between space-x-4 mt-6">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Back
              </button>
              <button
                onClick={nextStep}
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Next
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Job Support
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you been Transitioned your knowledge & functions?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobSupport.transitionDelivery}
                  onChange={handleChange("jobSupport", "transitionDelivery")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you learned the Strengths & Improvements for your role?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobSupport.roleStrengths}
                  onChange={handleChange("jobSupport", "roleStrengths")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Create a Document which outlines all that was taught during
                  Transitioning.
                </label>
                <input
                  type="text"
                  value={formValues.jobSupport.transitionDocument}
                  onChange={handleChange("jobSupport", "transitionDocument")}
                  placeholder="Enter Transition Document link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you been Onboarded by your LCVP?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobSupport.onboardingLCVP}
                  onChange={handleChange("jobSupport", "onboardingLCVP")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you conducted an Onboarding meet with your Members, if
                  any?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobSupport.onboardingMembers}
                  onChange={handleChange("jobSupport", "onboardingMembers")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Create a Document which outlines the Key Takeaways of
                  Onboarding.
                </label>
                <input
                  type="text"
                  value={formValues.jobSupport.onboardingDocument}
                  onChange={handleChange("jobSupport", "onboardingDocument")}
                  placeholder="Enter Onboarding Document link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you received Tools & Documents for your role by your
                  LCVP?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobSupport.toolsLCVP}
                  onChange={handleChange("jobSupport", "toolsLCVP")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you received Tools & Documents for your role by your
                  Preceeding TLD?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobSupport.toolsTLD}
                  onChange={handleChange("jobSupport", "toolsTLD")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you downscaled said Tools & Documents with your Members,
                  if any?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.jobSupport.downscaleTools}
                  onChange={handleChange("jobSupport", "downscaleTools")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Document the supporting Tools & Documents in a single folder.
                </label>
                <input
                  type="text"
                  value={formValues.jobSupport.toolsDocument}
                  onChange={handleChange("jobSupport", "toolsDocument")}
                  placeholder="Enter Tools Document link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
            </div>
            <div className="flex justify-between space-x-4 mt-6">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Back
              </button>
              <button
                onClick={nextStep}
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Next
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Community
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are you a part of your LC's Internal Communication Channels?
                  (WhatsApp, Instagram, Facebook)
                </label>
                <input
                  type="checkbox"
                  checked={formValues.community.internalComms}
                  onChange={handleChange("community", "internalComms")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are you a part of your Portfolio's Internal Communication
                  Channel?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.community.portfolioComms}
                  onChange={handleChange("community", "portfolioComms")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Do you keep a track of National Updates? (National
                  Initiatives, Projects, Roles and Seminars)
                </label>
                <input
                  type="checkbox"
                  checked={formValues.community.nationalUpdates}
                  onChange={handleChange("community", "nationalUpdates")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are you added to AIESEC Pakistan's Facebook Group?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.community.facebookGroup}
                  onChange={handleChange("community", "facebookGroup")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you been downscaled the HR Policy?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.community.hrPolicy}
                  onChange={handleChange("community", "hrPolicy")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you been downscaled the Code of Ethics?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.community.codeOfEthics}
                  onChange={handleChange("community", "codeOfEthics")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Submit a proof that HR Policy & Code of Ethics downscaling
                  took place in your LC.
                </label>
                <input
                  type="text"
                  value={formValues.community.hrEthicsProof}
                  onChange={handleChange("community", "hrEthicsProof")}
                  placeholder="Enter HR Ethics Proof link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
            </div>
            <div className="flex justify-between space-x-4 mt-6">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Back
              </button>
              <button
                onClick={nextStep}
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Next
              </button>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Community Engagement
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Do you have a functional AIESEC Email?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.communityEngagement.functionalEmail}
                  onChange={handleChange(
                    "communityEngagement",
                    "functionalEmail"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you added your External Email Signature?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.communityEngagement.emailSignature}
                  onChange={handleChange(
                    "communityEngagement",
                    "emailSignature"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Do you have a functional EXPA which you know how to use?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.communityEngagement.functionalEXPA}
                  onChange={handleChange(
                    "communityEngagement",
                    "functionalEXPA"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are Accountability Routines (Eg: Townhalls) taking place in
                  GBMs & Portfolio meets?
                </label>
                <input
                  type="checkbox"
                  checked={
                    formValues.communityEngagement.accountabilityRoutines
                  }
                  onChange={handleChange(
                    "communityEngagement",
                    "accountabilityRoutines"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are GB Engagement Events taking place?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.communityEngagement.gbEngagementEvents}
                  onChange={handleChange(
                    "communityEngagement",
                    "gbEngagementEvents"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are Portfolio Engagement Events taking place?
                </label>
                <input
                  type="checkbox"
                  checked={
                    formValues.communityEngagement.portfolioEngagementEvents
                  }
                  onChange={handleChange(
                    "communityEngagement",
                    "portfolioEngagementEvents"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are Quarterly Appraisals at least taking place?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.communityEngagement.quarterlyAppraisals}
                  onChange={handleChange(
                    "communityEngagement",
                    "quarterlyAppraisals"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are Quarterly RnRs at least taking place?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.communityEngagement.quarterlyRnRs}
                  onChange={handleChange(
                    "communityEngagement",
                    "quarterlyRnRs"
                  )}
                  className="ml-2"
                />
              </div>
            </div>
            <div className="flex justify-between space-x-4 mt-6">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Back
              </button>
              <button
                onClick={nextStep}
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Next
              </button>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Environment & Wellbeing
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Has your Physical Workspace been decided with your Team?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.environmentWellbeing.physicalWorkspace}
                  onChange={handleChange(
                    "environmentWellbeing",
                    "physicalWorkspace"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Has your Virtual Workspace been decided with your Team?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.environmentWellbeing.virtualWorkspace}
                  onChange={handleChange(
                    "environmentWellbeing",
                    "virtualWorkspace"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Document the Workspace Plans
                </label>
                <input
                  type="text"
                  value={formValues.environmentWellbeing.workspacePlans}
                  onChange={handleChange(
                    "environmentWellbeing",
                    "workspacePlans"
                  )}
                  placeholder="Enter Workspace Plans link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Do you have a O2O Routine with your LCVP?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.environmentWellbeing.o2oRoutine}
                  onChange={handleChange("environmentWellbeing", "o2oRoutine")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Do you have a O2O Routine with your Members, if any?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.environmentWellbeing.o2oMembers}
                  onChange={handleChange("environmentWellbeing", "o2oMembers")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Does your LC/LCVP provide a TL Coaching Routine? (Eg:
                  Portfolio Meet)
                </label>
                <input
                  type="checkbox"
                  checked={formValues.environmentWellbeing.tlCoaching}
                  onChange={handleChange("environmentWellbeing", "tlCoaching")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Is your Mental Wellbeing catered to?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.environmentWellbeing.mentalWellbeing}
                  onChange={handleChange(
                    "environmentWellbeing",
                    "mentalWellbeing"
                  )}
                  className="ml-2"
                />
              </div>
            </div>
            <div className="flex justify-between space-x-4 mt-6">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Back
              </button>
              <button
                onClick={nextStep}
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Next
              </button>
            </div>
          </div>
        );
      case 7:
        return (
          <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Team Practice
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you created a Team Vision?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.teamVision}
                  onChange={handleChange("teamPractice", "teamVision")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you established Team Rules & Meeting Routines?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.teamRules}
                  onChange={handleChange("teamPractice", "teamRules")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you created Team Goals & Strategies?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.teamGoals}
                  onChange={handleChange("teamPractice", "teamGoals")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you decided upon the KPIs & MoS?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.teamKPIs}
                  onChange={handleChange("teamPractice", "teamKPIs")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you conducted a GTKEO Meet?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.gtkeoMeet}
                  onChange={handleChange("teamPractice", "gtkeoMeet")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you established Team Days?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.teamDays}
                  onChange={handleChange("teamPractice", "teamDays")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you decided on Engagement Routines?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.engagementRoutines}
                  onChange={handleChange("teamPractice", "engagementRoutines")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Share the Engagement Routines
                </label>
                <input
                  type="text"
                  value={formValues.teamPractice.engagementRoutinesShare}
                  onChange={handleChange(
                    "teamPractice",
                    "engagementRoutinesShare"
                  )}
                  placeholder="Enter Engagement Routines Share link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are you evaluating Team Performance?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.teamPerformanceEvaluation}
                  onChange={handleChange(
                    "teamPractice",
                    "teamPerformanceEvaluation"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Share your Performance Evaluation system
                </label>
                <input
                  type="text"
                  value={formValues.teamPractice.performanceEvaluationSystem}
                  onChange={handleChange(
                    "teamPractice",
                    "performanceEvaluationSystem"
                  )}
                  placeholder="Enter Performance Evaluation System link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Share your Quarterly Impact Reports
                </label>
                <input
                  type="text"
                  value={formValues.teamPractice.quarterlyImpactReports}
                  onChange={handleChange(
                    "teamPractice",
                    "quarterlyImpactReports"
                  )}
                  placeholder="Enter Quarterly Impact Reports link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are you providing/engaging in Lead Spaces?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.leadSpaces}
                  onChange={handleChange("teamPractice", "leadSpaces")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Are you providing/engaging in Personal & Functional
                  Development Spaces?
                </label>
                <input
                  type="checkbox"
                  checked={
                    formValues.teamPractice.personalFunctionalDevelopment
                  }
                  onChange={handleChange(
                    "teamPractice",
                    "personalFunctionalDevelopment"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Has your LCVP provided Functional Spaces?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.functionalSpaces}
                  onChange={handleChange("teamPractice", "functionalSpaces")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Has any GB Development spaces been conducted?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.gbDevelopmentSpaces}
                  onChange={handleChange("teamPractice", "gbDevelopmentSpaces")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Have you conducted a Team Closing meet?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.teamClosingMeet}
                  onChange={handleChange("teamPractice", "teamClosingMeet")}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Has your LCVP conducted a Portfolio Closing Meet?
                </label>
                <input
                  type="checkbox"
                  checked={formValues.teamPractice.portfolioClosingMeet}
                  onChange={handleChange(
                    "teamPractice",
                    "portfolioClosingMeet"
                  )}
                  className="ml-2"
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Share a Semester Impact Report
                </label>
                <input
                  type="text"
                  value={formValues.teamPractice.semesterImpactReport}
                  onChange={handleChange(
                    "teamPractice",
                    "semesterImpactReport"
                  )}
                  placeholder="Enter Semester Impact Report link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
            </div>
            <div className="flex justify-between space-x-4 mt-6">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Back
              </button>
              <button
                onClick={handleSubmit}
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Submit
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div className="text-center text-2xl">
            <p className="pb-10">Form Completed</p>
            <Button
              onClick={handleSubmit}
              borderRadius="1.75rem"
              className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
            >
              Submit
            </Button>
          </div>
        );
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-2 sm:px-4">
      <div className="w-full max-w-4xl p-2 sm:p-6">
        <h1 className="text-2xl sm:text-3xl font-bold pb-6 sm:pb-10 text-center">
          MX Standards Form
        </h1>
        <div className="bg-white p-4 sm:p-8 rounded-lg shadow-md dark:bg-gray-800 mx-auto w-full">
          {renderStep()}
          <div className="flex justify-between mt-4">
            <Button
              onClick={handleSaveProgress}
              borderRadius="1.75rem"
              className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
            >
              Save Progress
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiStepChecklistForm;
