import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { auth, googleProvider, db } from "../firebase/firebase";
import { signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { useUser } from "../contexts/UserContext";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { IconBrandGoogle } from "@tabler/icons-react";

const Home = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const { setRole } = useUser();

  const handleEmailSignin = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email.endsWith("@aiesec.net")) {
      setMessage("Only AIESEC emails are allowed");
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        setMessage("Please verify your email before signing in.");
        return;
      }

      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userRole = userDoc.data().role;
        setRole(userRole);
      }

      navigate("/dashboard");
    } catch (error: any) {
      console.error(error);
      setMessage("Signin failed: " + error.message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const email = result.user?.email;
      if (email && email.endsWith("@aiesec.net")) {
        console.log(true);
        const user = result.user;
        if (!user.emailVerified) {
          setMessage("Please verify your email before signing in.");
          auth.signOut();
          return;
        }

        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userRole = userDoc.data().role;
          setRole(userRole);
        }

        navigate("/dashboard");
      } else {
        alert("You must sign in with an @aiesec.net email address");
        auth.signOut();
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
      alert("Error signing in. Please try again.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-nexusPurple text-white">
      <div className="flex flex-col md:flex-row flex-1">
        {/* Left Column - Hero Section */}
        <div className="relative flex justify-center items-center h-screen md:h-auto flex-1 px-8 py-12 bg-gradient-to-b from-purple-700 to-purple-900">
          {/* Decorative Background */}
          <div
            className="absolute inset-0 w-full h-full
              bg-gradient-to-t from-purple-600 to-transparent
              blur-[200px] opacity-50"
          ></div>

          {/* Content */}
          <div className="relative z-10 text-center">
            {/* Logo */}
            <img
              src="nexus.png"
              alt="Nexus Logo"
              className="w-[60vw] sm:w-[50vw] md:w-[40vw] lg:w-[30vw] mx-auto animate-fadeIn"
            />

            {/* Slogan */}
            <motion.h1
              className="mt-6 text-4xl md:text-5xl lg:text-6xl font-extrabold tracking-wide leading-tight"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.2 }}
            >
              Your <span className="text-purple-300">Gateway</span> to Our World
            </motion.h1>

            {/* Buttons */}
            <motion.div
              className="mt-8 flex justify-center space-x-4"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.5, delay: 0.8 }}
            ></motion.div>
          </div>
        </div>

        {/* Right Column - Illustration/Graphic */}
        <div className="flex-1 flex items-center justify-center bg-white p-8">
          <motion.div
            className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-gray-800"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            <h2 className="text-2xl font-bold text-center mb-4 text-gray-900">
              Welcome to NEXUS!
            </h2>
            <p className="text-sm text-center text-gray-500 mb-6">
              Sign in to your account to continue.
            </p>

            <form className="space-y-6" onSubmit={handleEmailSignin}>
              {/* Email Input */}
              <div className="relative">
                <Label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </Label>
                <div className="mt-1">
                  <Input
                    id="email"
                    type="email"
                    placeholder="user@aiesec.net"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
                {message.includes("email") && (
                  <p className="mt-2 text-sm text-red-500">{message}</p>
                )}
              </div>

              {/* Password Input */}
              <div className="relative">
                <Label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </Label>
                <div className="mt-1">
                  <Input
                    id="password"
                    type="password"
                    placeholder="••••••••"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
                {message.includes("Password") && (
                  <p className="mt-2 text-sm text-red-500">{message}</p>
                )}
              </div>

              {/* Sign-In Button */}
              <button
                type="submit"
                className="w-full bg-purple-600 hover:bg-purple-700 text-black font-semibold py-3 px-4 rounded-lg shadow-md transition-transform transform hover:scale-105"
              >
                Sign in
              </button>

              {/* Divider */}
              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              {/* Google Sign-In */}
              <button
                type="button"
                onClick={signInWithGoogle}
                className="flex items-center justify-center w-full border border-gray-300 bg-white text-gray-700 font-medium py-3 px-4 rounded-lg shadow-sm hover:bg-gray-100 hover:scale-105 transition-transform transform"
              >
                <IconBrandGoogle className="h-5 w-5 mr-2" />
                Sign in with Google
              </button>

              {/* Additional Actions */}
              <div className="flex justify-between text-sm mt-4">
                <button
                  type="button"
                  onClick={() => navigate("/reset-password")}
                  className="text-purple-600 hover:underline"
                >
                  Forgot Password?
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/signup")}
                  className="text-purple-600 hover:underline"
                >
                  Create Account
                </button>
              </div>
            </form>

            {/* Global Error Message */}
            {message &&
              !message.includes("email") &&
              !message.includes("Password") && (
                <p className="mt-6 text-center text-sm text-red-500">
                  {message}
                </p>
              )}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Home;
