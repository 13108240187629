// // import React from "react";
// // import { motion } from "framer-motion";
// // import { FaChevronDown } from "react-icons/fa"; // Importing a down arrow icon from FontAwesome

// // const Hero = ({ loc }: { loc: string }) => {

// //   const handleScroll = () => {
// //     const element = document.getElementById(loc);
// //     if (element) {
// //       element.scrollIntoView({ behavior: "smooth" });
// //     }
// //   };

// //   return (
// //     <div className="relative flex justify-center items-center h-screen bg-nexusPurple overflow-hidden">
// //       {/* Outer ring effect */}
// //       <motion.div
// //         initial={{ scale: 0, opacity: 0 }}
// //         animate={{ scale: 1, opacity: 1 }}
// //         transition={{ duration: 1.5, ease: "easeOut" }}
// //         className="absolute w-[50vw] h-[50vw] max-w-[580px] max-h-[580px] md:max-w-[780px] md:max-h-[780px] rounded-full border-4 border-opacity-50 border-white ring-4 ring-gray-300"
// //       ></motion.div>

// //       <motion.div
// //         initial={{ scale: 0, opacity: 0 }}
// //         animate={{ scale: 1, opacity: 1 }}
// //         transition={{ duration: 2, ease: "easeOut" }}
// //         className="absolute w-[50vw] h-[50vw] max-w-[580px] max-h-[580px] md:max-w-[780px] md:max-h-[780px] rounded-full border-4 border-opacity-200 border-white ring-4 ring-white blur-[150px] shadow-[0_0_100px_100px_rgba(255,255,255,0.5)]"
// //       ></motion.div>

// //       <motion.div
// //         initial={{ scale: 0, opacity: 0 }}
// //         animate={{ scale: 1, opacity: 0.3 }}
// //         transition={{ duration: 2.5, ease: "easeOut" }}
// //         className="absolute w-[50vw] h-[50vw] max-w-[580px] max-h-[580px] md:max-w-[780px] md:max-h-[780px] rounded-full bg-gradient-to-r from-gray-700 to-white opacity-50 blur-6xl"
// //       ></motion.div>

// //       <div className="z-10 text-center">
// //         {/* Logo */}
// //         <img
// //           src="nexus.png"
// //           alt="Nexus Logo"
// //           width={650}
// //           height={450}
// //           className="animate-fadeIn"
// //         />

// //         {/* Slogan */}
// //         <motion.div
// //           className="mt-4"
// //           initial={{ opacity: 0, y: 50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1.5, delay: 0.5 }}
// //         >
// //           <h1 className="text-white text-5xl font-glacial font-bold tracking-wide">
// //             Welcome to Our <span className="text-purple">World</span>
// //           </h1>
// //         </motion.div>

// //         {/* Subtext */}
// //         <motion.div
// //           className="mt-2"
// //           initial={{ opacity: 0, scale: 0.8 }}
// //           animate={{ opacity: 1, scale: 1 }}
// //           transition={{ duration: 1.5, delay: 0.8 }}
// //         >
// //         </motion.div>

// //         {/* Bouncing Down Arrow */}
// //         <motion.a
// //           onClick={handleScroll} // Calls handleScroll when clicked
// //           initial={{ y: 0 }}
// //           animate={{ y: [0, 15, 0] }}
// //           transition={{ repeat: Infinity, duration: 1.5 }}
// //           className="mt-10 inline-block text-white"
// //         >
// //           <FaChevronDown size={40} />
// //         </motion.a>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Hero;

// import React from "react";
// import { motion } from "framer-motion";
// import { FaChevronDown } from "react-icons/fa"; // Importing a down arrow icon from FontAwesome

// const Hero = ({ loc }: { loc: string }) => {

//   const handleScroll = () => {
//     const element = document.getElementById(loc);
//     if (element) {
//       element.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   return (
//     <div className="relative flex justify-center items-center h-screen bg-nexusPurple overflow-hidden">
//       {/* Outer ring effect */}
//       <motion.div
//         initial={{ scale: 0, opacity: 0 }}
//         animate={{ scale: 1, opacity: 1 }}
//         transition={{ duration: 1.5, ease: "easeOut" }}
//         className="
//           absolute
//           w-[90vw] h-[90vw]     /* Large circle for mobile - creates the oval cut-off */
//           sm:w-[70vw] sm:h-[70vw] /* Smaller for small tablets */
//           md:w-[50vw] md:h-[50vw] /* Medium size for tablets */
//           lg:w-[45vw] lg:h-[45vw] /* Larger laptops and desktops */
//           xl:w-[40vw] xl:h-[40vw] /* Extra large for bigger monitors */
//           max-w-[580px] max-h-[580px]
//           md:max-w-[780px] md:max-h-[780px]
//           rounded-full border-4 border-opacity-50 border-white ring-4 ring-gray-300"
//       ></motion.div>

//       <motion.div
//         initial={{ scale: 0, opacity: 0 }}
//         animate={{ scale: 1, opacity: 1 }}
//         transition={{ duration: 2, ease: "easeOut" }}
//         className="
//           absolute
//           w-[90vw] h-[90vw]     /* Large circle for mobile */
//           sm:w-[70vw] sm:h-[70vw] /* Smaller for small tablets */
//           md:w-[50vw] md:h-[50vw] /* Medium size for tablets */
//           lg:w-[45vw] lg:h-[45vw] /* Larger laptops and desktops */
//           xl:w-[40vw] xl:h-[40vw] /* Extra large for bigger monitors */
//           max-w-[580px] max-h-[580px]
//           md:max-w-[780px] md:max-h-[780px]
//           rounded-full border-4 border-opacity-200 border-white ring-4 ring-white blur-[150px] shadow-[0_0_100px_100px_rgba(255,255,255,0.5)]"
//       ></motion.div>

//       <motion.div
//         initial={{ scale: 0, opacity: 0 }}
//         animate={{ scale: 1, opacity: 0.3 }}
//         transition={{ duration: 2.5, ease: "easeOut" }}
//         className="
//           absolute
//           w-[90vw] h-[90vw]     /* Large circle for mobile */
//           sm:w-[70vw] sm:h-[70vw] /* Smaller for small tablets */
//           md:w-[50vw] md:h-[50vw] /* Medium size for tablets */
//           lg:w-[45vw] lg:h-[45vw] /* Larger laptops and desktops */
//           xl:w-[40vw] xl:h-[40vw] /* Extra large for bigger monitors */
//           max-w-[580px] max-h-[580px]
//           md:max-w-[780px] md:max-h-[780px]
//           rounded-full bg-gradient-to-r from-gray-700 to-white opacity-50 blur-6xl"
//       ></motion.div>

//       <div className="z-10 text-center">
//         {/* Logo */}
//         <img
//           src="nexus.png"
//           alt="Nexus Logo"
//           width={650}
//           height={450}
//           className="animate-fadeIn"
//         />

//         {/* Slogan */}
//         <motion.div
//           className="mt-4"
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1.5, delay: 0.5 }}
//         >
//           <h1 className="text-white text-5xl font-glacial font-bold tracking-wide">
//             Welcome to Our <span className="text-purple">World</span>
//           </h1>
//         </motion.div>

//         {/* Subtext */}
//         <motion.div
//           className="mt-2"
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 1.5, delay: 0.8 }}
//         >
//         </motion.div>

//         {/* Bouncing Down Arrow */}
//         <motion.a
//           onClick={handleScroll} // Calls handleScroll when clicked
//           initial={{ y: 0 }}
//           animate={{ y: [0, 15, 0] }}
//           transition={{ repeat: Infinity, duration: 1.5 }}
//           className="mt-10 inline-block text-white"
//         >
//           <FaChevronDown size={40} />
//         </motion.a>
//       </div>
//     </div>
//   );
// };

// export default Hero;

import React from "react";
import { motion } from "framer-motion";
import { FaChevronDown } from "react-icons/fa"; // Importing a down arrow icon from FontAwesome

const Hero = ({ loc }: { loc: string }) => {
  const handleScroll = () => {
    const element = document.getElementById(loc);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative flex justify-center items-center h-screen bg-nexusPurple overflow-hidden">
      {/* Outer ring effect */}
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
        className="
          absolute 
          w-[90vw] h-[90vw]     /* Large circle for mobile - creates the oval cut-off */
          sm:w-[70vw] sm:h-[70vw] /* Smaller for small tablets */
          md:w-[60vw] md:h-[60vw] /* Medium size for tablets */
          lg:w-[50vw] lg:h-[50vw] /* Larger laptops and desktops */
          xl:w-[40vw] xl:h-[40vw] /* Extra large for bigger monitors */
          max-w-[580px] max-h-[580px] 
          md:max-w-[780px] md:max-h-[780px] 
          rounded-full border-4 border-opacity-50 border-white ring-4 ring-gray-300"
      ></motion.div>

      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 2, ease: "easeOut" }}
        className="
          absolute 
          w-[90vw] h-[90vw]     /* Large circle for mobile */
          sm:w-[70vw] sm:h-[70vw] /* Smaller for small tablets */
          md:w-[60vw] md:h-[60vw] /* Medium size for tablets */
          lg:w-[50vw] lg:h-[50vw] /* Larger laptops and desktops */
          xl:w-[40vw] xl:h-[40vw] /* Extra large for bigger monitors */
          max-w-[580px] max-h-[580px] 
          md:max-w-[780px] md:max-h-[780px] 
          rounded-full border-4 border-opacity-200 border-white ring-4 ring-white blur-[150px] shadow-[0_0_100px_100px_rgba(255,255,255,0.5)]"
      ></motion.div>

      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 0.3 }}
        transition={{ duration: 2.5, ease: "easeOut" }}
        className="
          absolute 
          w-[90vw] h-[90vw]     /* Large circle for mobile */
          sm:w-[70vw] sm:h-[70vw] /* Smaller for small tablets */
          md:w-[60vw] md:h-[60vw] /* Medium size for tablets */
          lg:w-[50vw] lg:h-[50vw] /* Larger laptops and desktops */
          xl:w-[40vw] xl:h-[40vw] /* Extra large for bigger monitors */
          max-w-[580px] max-h-[580px] 
          md:max-w-[780px] md:max-h-[780px] 
          rounded-full bg-gradient-to-r from-gray-700 to-white opacity-50 blur-6xl"
      ></motion.div>

      <div className="pt-20 z-10 text-center">
        {/* Responsive Logo */}
        <img
          src="nexus.png"
          alt="Nexus Logo"
          className="
            w-[75vw]     /* Dynamic width for mobile */
            sm:w-[70vw]  /* Smaller for small tablets */
            md:w-[75vw]  /* Medium for tablets */
            lg:w-[75vw]  /* Larger for laptops */
            xl:w-[90vw]  /* Extra large for bigger monitors */
            max-w-[600px]  /* Limit the max size */
            mx-auto        /* Centered horizontally */
            animate-fadeIn"
        />

        {/* Responsive Slogan */}
        <motion.div
          className="
            mt-4 
            text-white 
            text-3xl    /* Default for small screens */
            sm:text-2xl /* Slightly larger for small tablets */
            md:text-3xl /* Larger for tablets */
            lg:text-5xl /* Larger for laptops */
            xl:text-4xl /* Extra large for monitors */
            font-glacial font-bold tracking-wide"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, delay: 0.5 }}
        >
          Welcome to Our <span className="text-purple">World</span>
        </motion.div>

        {/* Bouncing Down Arrow */}
        <motion.a
          onClick={handleScroll} // Calls handleScroll when clicked
          initial={{ y: 0 }}
          animate={{ y: [0, 15, 0] }}
          transition={{ repeat: Infinity, duration: 1.5 }}
          className="mt-10 inline-block text-white"
        >
          <FaChevronDown size={40} />
        </motion.a>
      </div>
    </div>
  );
};

export default Hero;
