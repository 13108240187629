import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from './ui/moving-border';

type GCPFormData = {
  title: string;
  name: string;
  email: string;
  portfolio: string;
  category: string;
  localCommittee: string;
  gcpDetails: string;
  gcpLink: string;
  imageLink?: string; 
};

const EditGCPForm: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<GCPFormData>({
    title: '',
    name: '',
    email: '',
    portfolio: '',
    category: '',
    localCommittee: '',
    gcpDetails: '',
    gcpLink: '',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchGCPDetails();
  }, []);

  const fetchGCPDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/gcp/${id}`);
      const { title, name, email, portfolio, category, localCommittee, gcpDetails, gcpLink, imageLink } = response.data;
      setFormData({
        title,
        name,
        email,
        portfolio,
        category,
        localCommittee,
        gcpDetails,
        gcpLink,
        imageLink,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching GCP details:', error);
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const updatedData: GCPFormData = { ...formData };
    
    // Remove imageLink if it's empty
    if (!updatedData.imageLink) {
      delete updatedData.imageLink;
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/gcp/${id}`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      alert('GCP Form updated successfully');
      navigate('/gcplist');
    } catch (error) {
      console.error('Error updating GCP Form:', error);
      alert('Failed to update GCP Form');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 sm:p-12 w-full max-w-lg">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">
          GCP Form
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          {Object.keys(formData).map((key) => (
            <div key={key}>
              <label className="block text-sm font-medium text-gray-700">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </label>
              {key !== 'gcpDetails' ? (
                <input
                  name={key}
                  className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
                  type={key === 'email' ? 'email' : 'text'}
                  value={(formData as any)[key]} // Use dynamic access with a cast
                  onChange={handleChange}
                />
              ) : (
                <textarea
                  name={key}
                  className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
                  value={(formData as any)[key]} // Use dynamic access with a cast
                  onChange={handleChange}
                />
              )}
            </div>
          ))}
          <Button
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800"
            type="submit"
          >
            Update
          </Button>
        </form>
      </div>
    </div>
  );
};

export default EditGCPForm;
