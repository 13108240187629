import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tabs } from "../components/ui/tabs";
import { useMediaQuery } from "react-responsive";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../styles/SonaSurvey.css";

interface GeneralDetail {
  firstName: string;
  lastName: string;
  portfolio: string;
  lcName: string;
}

interface JobDesign {
  jobDescription: boolean;
  jobKpis: boolean;
  personalDevelopment: string;
  pdpSeptember: boolean;
  pdpDecember: boolean;
}

interface JobSupport {
  transitionDelivery: boolean;
  roleStrengths: boolean;
  transitionDocument: string;
  onboardingLCVP: boolean;
  onboardingMembers: boolean;
  onboardingDocument: string;
  toolsLCVP: boolean;
  toolsTLD: boolean;
  downscaleTools: boolean;
  toolsDocument: string;
}

interface Community {
  internalComms: boolean;
  portfolioComms: boolean;
  nationalUpdates: boolean;
  facebookGroup: boolean;
  hrPolicy: boolean;
  codeOfEthics: boolean;
  hrEthicsProof: string;
}

interface CommunityEngagement {
  functionalEmail: boolean;
  emailSignature: boolean;
  functionalEXPA: boolean;
  accountabilityRoutines: boolean;
  gbEngagementEvents: boolean;
  portfolioEngagementEvents: boolean;
  quarterlyAppraisals: boolean;
  quarterlyRnRs: boolean;
}

interface EnvironmentWellbeing {
  physicalWorkspace: boolean;
  virtualWorkspace: boolean;
  workspacePlans: string;
  o2oRoutine: boolean;
  o2oMembers: boolean;
  tlCoaching: boolean;
  mentalWellbeing: boolean;
}

interface TeamPractice {
  teamVision: boolean;
  teamRules: boolean;
  teamGoals: boolean;
  teamKPIs: boolean;
  gtkeoMeet: boolean;
  teamDays: boolean;
  engagementRoutines: boolean;
  engagementRoutinesShare: string;
  teamPerformanceEvaluation: boolean;
  performanceEvaluationSystem: string;
  quarterlyImpactReports: string;
  leadSpaces: boolean;
  personalFunctionalDevelopment: boolean;
  functionalSpaces: boolean;
  gbDevelopmentSpaces: boolean;
  teamClosingMeet: boolean;
  portfolioClosingMeet: boolean;
  semesterImpactReport: string;
}

interface MXFormResult {
  _id: string;
  generalDetail: GeneralDetail;
  jobDesign: JobDesign;
  jobSupport: JobSupport;
  community: Community;
  communityEngagement: CommunityEngagement;
  environmentWellbeing: EnvironmentWellbeing;
  teamPractice: TeamPractice;
}

const MXResults: React.FC = () => {
  const [data, setData] = useState<MXFormResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [selectedLC, setSelectedLC] = useState<string | null>(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState<string | null>(
    null
  );
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/checklist/mxform`
        );
        setData(response.data);
        setLoading(false);
      } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
          setError(err.message);
        } else {
          setError("An unexpected error occurred");
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateCompletionPercentage = (result: MXFormResult) => {
    const totalFields =
      Object.keys(result.jobDesign || {}).length +
      Object.keys(result.jobSupport || {}).length +
      Object.keys(result.community || {}).length +
      Object.keys(result.communityEngagement || {}).length +
      Object.keys(result.environmentWellbeing || {}).length +
      Object.keys(result.teamPractice || {}).length;

    const completedFields = [
      ...Object.values(result.jobDesign || {}),
      ...Object.values(result.jobSupport || {}),
      ...Object.values(result.community || {}),
      ...Object.values(result.communityEngagement || {}),
      ...Object.values(result.environmentWellbeing || {}),
      ...Object.values(result.teamPractice || {}),
    ].filter((field) => field).length;

    return totalFields > 0
      ? Math.round((completedFields / totalFields) * 100)
      : 0;
  };

  const calculateLCCompletion = (lcData: MXFormResult[]) => {
    const totalCompletion = lcData.reduce(
      (sum, d) => sum + calculateCompletionPercentage(d),
      0
    );
    return lcData.length > 0 ? Math.round(totalCompletion / lcData.length) : 0;
  };

  const calculateNationalCompletion = () => {
    const totalCompletion = data.reduce(
      (sum, d) => sum + calculateCompletionPercentage(d),
      0
    );
    return data.length > 0 ? Math.round(totalCompletion / data.length) : 0;
  };

  const lcOptions = [
    "Multan",
    "LUMS",
    "IBA",
    "Islamabad",
    "NUST",
    "South Karachi",
    "Karachi",
    "Hyderabad",
    "Lahore",
    "Faisalabad",
    "Giki",
  ];

  const portfolioOptions = [
    "MKT & PR",
    "iCX",
    "oGV",
    "oGT",
    "EwA",
    "BD",
    "MXP",
    "FnL",
  ];

  const renderDetails = (result: MXFormResult) => (
    <div>
      <h3 className="text-xl font-bold mb-4 text-gray-800 text-center">
        Job Design
      </h3>
      <div className="mb-4">
        <p>Job Description: {result.jobDesign.jobDescription ? "Yes" : "No"}</p>
        <p>Job KPIs: {result.jobDesign.jobKpis ? "Yes" : "No"}</p>
        <p>
          Personal Development:{" "}
          {result.jobDesign.personalDevelopment ? (
            <a
              href={result.jobDesign.personalDevelopment}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Document
            </a>
          ) : (
            "No"
          )}
        </p>
        <p>PDP September: {result.jobDesign.pdpSeptember ? "Yes" : "No"}</p>
        <p>PDP December: {result.jobDesign.pdpDecember ? "Yes" : "No"}</p>
      </div>

      <h3 className="text-xl font-bold mb-4 text-gray-800 text-center">
        Job Support
      </h3>
      <div className="mb-4">
        <p>
          Transition Delivery:{" "}
          {result.jobSupport.transitionDelivery ? "Yes" : "No"}
        </p>
        <p>Role Strengths: {result.jobSupport.roleStrengths ? "Yes" : "No"}</p>
        <p>
          Transition Document:{" "}
          {result.jobSupport.transitionDocument ? (
            <a
              href={result.jobSupport.transitionDocument}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Document
            </a>
          ) : (
            "No"
          )}
        </p>
        <p>
          Onboarding LCVP: {result.jobSupport.onboardingLCVP ? "Yes" : "No"}
        </p>
        <p>
          Onboarding Members:{" "}
          {result.jobSupport.onboardingMembers ? "Yes" : "No"}
        </p>
        <p>
          Onboarding Document:{" "}
          {result.jobSupport.onboardingDocument ? (
            <a
              href={result.jobSupport.onboardingDocument}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Document
            </a>
          ) : (
            "No"
          )}
        </p>
        <p>Tools LCVP: {result.jobSupport.toolsLCVP ? "Yes" : "No"}</p>
        <p>Tools TLD: {result.jobSupport.toolsTLD ? "Yes" : "No"}</p>
        <p>
          Downscale Tools: {result.jobSupport.downscaleTools ? "Yes" : "No"}
        </p>
        <p>
          Tools Document:{" "}
          {result.jobSupport.toolsDocument ? (
            <a
              href={result.jobSupport.toolsDocument}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Document
            </a>
          ) : (
            "No"
          )}
        </p>
      </div>

      <h3 className="text-xl font-bold mb-4 text-gray-800 text-center">
        Community
      </h3>
      <div className="mb-4">
        <p>Internal Comms: {result.community.internalComms ? "Yes" : "No"}</p>
        <p>Portfolio Comms: {result.community.portfolioComms ? "Yes" : "No"}</p>
        <p>
          National Updates: {result.community.nationalUpdates ? "Yes" : "No"}
        </p>
        <p>Facebook Group: {result.community.facebookGroup ? "Yes" : "No"}</p>
        <p>HR Policy: {result.community.hrPolicy ? "Yes" : "No"}</p>
        <p>Code of Ethics: {result.community.codeOfEthics ? "Yes" : "No"}</p>
        <p>
          HR Ethics Proof:{" "}
          {result.community.hrEthicsProof ? (
            <a
              href={result.community.hrEthicsProof}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Proof
            </a>
          ) : (
            "No"
          )}
        </p>
      </div>

      <h3 className="text-xl font-bold mb-4 text-gray-800 text-center">
        Community Engagement
      </h3>
      <div className="mb-4">
        <p>
          Functional Email:{" "}
          {result.communityEngagement.functionalEmail ? "Yes" : "No"}
        </p>
        <p>
          Email Signature:{" "}
          {result.communityEngagement.emailSignature ? "Yes" : "No"}
        </p>
        <p>
          Functional EXPA:{" "}
          {result.communityEngagement.functionalEXPA ? "Yes" : "No"}
        </p>
        <p>
          Accountability Routines:{" "}
          {result.communityEngagement.accountabilityRoutines ? "Yes" : "No"}
        </p>
        <p>
          GB Engagement Events:{" "}
          {result.communityEngagement.gbEngagementEvents ? "Yes" : "No"}
        </p>
        <p>
          Portfolio Engagement Events:{" "}
          {result.communityEngagement.portfolioEngagementEvents ? "Yes" : "No"}
        </p>
        <p>
          Quarterly Appraisals:{" "}
          {result.communityEngagement.quarterlyAppraisals ? "Yes" : "No"}
        </p>
        <p>
          Quarterly RnRs:{" "}
          {result.communityEngagement.quarterlyRnRs ? "Yes" : "No"}
        </p>
      </div>

      <h3 className="text-xl font-bold mb-4 text-gray-800 text-center">
        Environment Wellbeing
      </h3>
      <div className="mb-4">
        <p>
          Physical Workspace:{" "}
          {result.environmentWellbeing.physicalWorkspace ? "Yes" : "No"}
        </p>
        <p>
          Virtual Workspace:{" "}
          {result.environmentWellbeing.virtualWorkspace ? "Yes" : "No"}
        </p>
        <p>
          Workspace Plans:{" "}
          {result.environmentWellbeing.workspacePlans ? (
            <a
              href={result.environmentWellbeing.workspacePlans}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Plans
            </a>
          ) : (
            "No"
          )}
        </p>
        <p>
          O2O Routine: {result.environmentWellbeing.o2oRoutine ? "Yes" : "No"}
        </p>
        <p>
          O2O Members: {result.environmentWellbeing.o2oMembers ? "Yes" : "No"}
        </p>
        <p>
          TL Coaching: {result.environmentWellbeing.tlCoaching ? "Yes" : "No"}
        </p>
        <p>
          Mental Wellbeing:{" "}
          {result.environmentWellbeing.mentalWellbeing ? "Yes" : "No"}
        </p>
      </div>

      <h3 className="text-xl font-bold mb-4 text-gray-800 text-center">
        Team Practice
      </h3>
      <div className="mb-4">
        <p>Team Vision: {result.teamPractice.teamVision ? "Yes" : "No"}</p>
        <p>Team Rules: {result.teamPractice.teamRules ? "Yes" : "No"}</p>
        <p>Team Goals: {result.teamPractice.teamGoals ? "Yes" : "No"}</p>
        <p>Team KPIs: {result.teamPractice.teamKPIs ? "Yes" : "No"}</p>
        <p>GTKEO Meet: {result.teamPractice.gtkeoMeet ? "Yes" : "No"}</p>
        <p>Team Days: {result.teamPractice.teamDays ? "Yes" : "No"}</p>
        <p>
          Engagement Routines:{" "}
          {result.teamPractice.engagementRoutines ? "Yes" : "No"}
        </p>
        <p>
          Engagement Routines Share:{" "}
          {result.teamPractice.engagementRoutinesShare ? (
            <a
              href={result.teamPractice.engagementRoutinesShare}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Details
            </a>
          ) : (
            "No"
          )}
        </p>
        <p>
          Team Performance Evaluation:{" "}
          {result.teamPractice.teamPerformanceEvaluation ? "Yes" : "No"}
        </p>
        <p>
          Performance Evaluation System:{" "}
          {result.teamPractice.performanceEvaluationSystem ? (
            <a
              href={result.teamPractice.performanceEvaluationSystem}
              target="_blank"
              rel="noopener noreferrer"
            >
              View System
            </a>
          ) : (
            "No"
          )}
        </p>
        <p>
          Quarterly Impact Reports:{" "}
          {result.teamPractice.quarterlyImpactReports ? (
            <a
              href={result.teamPractice.quarterlyImpactReports}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Reports
            </a>
          ) : (
            "No"
          )}
        </p>
        <p>Lead Spaces: {result.teamPractice.leadSpaces ? "Yes" : "No"}</p>
        <p>
          Personal Functional Development:{" "}
          {result.teamPractice.personalFunctionalDevelopment ? "Yes" : "No"}
        </p>
        <p>
          Functional Spaces:{" "}
          {result.teamPractice.functionalSpaces ? "Yes" : "No"}
        </p>
        <p>
          GB Development Spaces:{" "}
          {result.teamPractice.gbDevelopmentSpaces ? "Yes" : "No"}
        </p>
        <p>
          Team Closing Meet:{" "}
          {result.teamPractice.teamClosingMeet ? "Yes" : "No"}
        </p>
        <p>
          Portfolio Closing Meet:{" "}
          {result.teamPractice.portfolioClosingMeet ? "Yes" : "No"}
        </p>
        <p>
          Semester Impact Report:{" "}
          {result.teamPractice.semesterImpactReport ? (
            <a
              href={result.teamPractice.semesterImpactReport}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Report
            </a>
          ) : (
            "No"
          )}
        </p>
      </div>
    </div>
  );

  const calculateNationalSummary = () => {
    return lcOptions.map((lcName) => {
      const lcData = data.filter((d) => d.generalDetail.lcName === lcName);
      const lcCompletion = calculateLCCompletion(lcData);
      return {
        lcName,
        lcCompletion,
      };
    });
  };

  const renderNationalSummary = () => {
    const nationalCompletion = calculateNationalCompletion(); // Calculate the overall national completion
    const nationalSummary = calculateNationalSummary();
    return (
      <div className="w-full p-6 bg-white rounded-lg shadow-md">
        <h3 className="text-xl font-bold mb-4 text-gray-800 text-center">
          National Summary
        </h3>
        <div className="flex justify-center mb-4">
          <div style={{ width: 150, height: 150 }}>
            <CircularProgressbar
              value={nationalCompletion}
              text={`${nationalCompletion}%`}
              styles={buildStyles({
                textSize: "12px",
                pathColor: "#3b82f6",
                textColor: "#3b82f6",
                trailColor: "#d6d6d6",
                backgroundColor: "#f8f8f8",
              })}
            />
          </div>
        </div>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-3 bg-gray-200 text-gray-600 font-bold">
                LC Name
              </th>
              <th className="py-2 px-3 bg-gray-200 text-gray-600 font-bold">
                Completion %
              </th>
            </tr>
          </thead>
          <tbody>
            {nationalSummary.map(({ lcName, lcCompletion }) => (
              <tr key={lcName}>
                <td className="py-2 px-3 border font-bold text-gray-700">
                  {lcName}
                </td>
                <td className="py-2 px-3 border text-gray-700">
                  {lcCompletion}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const calculatePortfolioCompletion = (filteredData: MXFormResult[]) => {
    const portfolioCompletion = portfolioOptions.reduce((acc, portfolio) => {
      const portfolioData = filteredData.filter(
        (d) => d.generalDetail.portfolio === portfolio
      );
  
      if (portfolioData.length > 0) {
        // Total possible fields to be marked as "Yes" (55 per respondent)
        const totalPossibleFields = portfolioData.length * 55;
  
        // Sum the number of "Yes" responses across all respondents in this portfolio
        const totalYesCount = portfolioData.reduce((sum, d) => {
          return (
            sum +
            [
              ...Object.values(d.jobDesign || {}),
              ...Object.values(d.jobSupport || {}),
              ...Object.values(d.community || {}),
              ...Object.values(d.communityEngagement || {}),
              ...Object.values(d.environmentWellbeing || {}),
              ...Object.values(d.teamPractice || {}),
            ].filter((field) => field === true).length
          );
        }, 0);
  
        // Calculate completion percentage
        const completionPercentage = Math.round((totalYesCount / totalPossibleFields) * 100);
        acc[portfolio] = completionPercentage;
      } else {
        acc[portfolio] = 0; // Set to 0 if no data for the portfolio
      }
  
      return acc;
    }, {} as Record<string, number>);
  
    return portfolioCompletion;
  };
  

  const calculateSummary = (filteredData: MXFormResult[], lcName?: string) => {
    const totalResponses = filteredData.length;
    const portfolioCompletion = calculatePortfolioCompletion(filteredData);
    console.log(portfolioCompletion);
    const overallCompletion = calculateLCCompletion(filteredData);

    return {
      totalResponses,
      lcName,
      portfolioSummary: portfolioCompletion,
      overallCompletion,
    };
  };

  const renderSummary = (summaryData: ReturnType<typeof calculateSummary>) => (
    <div className="w-full p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-xl font-bold mb-4 text-gray-800 text-center">
        {summaryData.lcName
          ? `Summary for AIESEC in ${summaryData.lcName}`
          : "National Summary"}
      </h3>
      <div className="flex justify-center mb-4">
        <div style={{ width: 150, height: 150 }}>
          <CircularProgressbar
            value={summaryData.overallCompletion}
            text={`${summaryData.overallCompletion}%`}
            styles={buildStyles({
              textSize: "12px",
              pathColor: "#3b82f6",
              textColor: "#3b82f6",
              trailColor: "#d6d6d6",
              backgroundColor: "#f8f8f8",
            })}
          />
        </div>
      </div>
      <table className="min-w-full bg-white">
        <tbody>
          <tr>
            <td className="py-2 px-3 border font-bold text-gray-700">
              Total Responses
            </td>
            <td className="py-2 px-3 border text-gray-700">
              {summaryData.totalResponses}
            </td>
          </tr>
          {Object.entries(summaryData.portfolioSummary).map(
            ([portfolio, completion]) => (
              <tr key={portfolio}>
                <td className="py-2 px-3 border font-bold text-gray-700">
                  {portfolio}
                </td>
                <td className="py-2 px-3 border text-gray-700">
                  {completion}%
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );

  const lcTabs = lcOptions.map((lcName) => {
    const lcData = data.filter((d) => d.generalDetail.lcName === lcName);
    const lcSummaryData = calculateSummary(lcData, lcName);
    const lcSummary = renderSummary(lcSummaryData);

    return {
      title: lcName,
      value: lcName,
      content: (
        <>
          <div className="flex justify-center mb-4"></div>
          <Tabs
            tabs={[
              {
                title: "Overall Summary",
                value: "overall-summary",
                content: lcSummary,
              },
              ...portfolioOptions.map((portfolio) => {
                const portfolioData = lcData.filter(
                  (d) => d.generalDetail.portfolio === portfolio
                );

                const portfolioCompletion = calculateCompletionPercentage({
                  ...portfolioData[0],
                });

                return {
                  title: portfolio,
                  value: portfolio,
                  content: (
                    <div className="w-full p-6 bg-white rounded-lg shadow-md">
                      <h2 className="text-2xl font-bold mb-4 text-gray-800 text-center">
                        {portfolio}
                      </h2>
                      <div className="flex justify-center mb-4">
                        {/* <div style={{ width: 150, height: 150 }}>
                          <CircularProgressbar
                            value={portfolioCompletion}
                            text={`${portfolioCompletion}%`}
                            styles={buildStyles({
                              textSize: "12px",
                              pathColor: "#3b82f6",
                              textColor: "#3b82f6",
                              trailColor: "#d6d6d6",
                              backgroundColor: "#f8f8f8",
                            })}
                          />
                        </div> */}
                      </div>
                      <table className="min-w-full bg-white">
                        <thead>
                          <tr>
                            <th className="py-2 px-3 bg-gray-200 text-gray-600 font-bold">
                              Name
                            </th>
                            <th className="py-2 px-3 bg-gray-200 text-gray-600 font-bold">
                              Implementation %
                            </th>
                            <th className="py-2 px-3 bg-gray-200 text-gray-600 font-bold">
                              View Details
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {portfolioData.map((d) => (
                            <tr key={d._id}>
                              <td className="py-2 px-3 border">{`${d.generalDetail.firstName} ${d.generalDetail.lastName}`}</td>
                              <td className="py-2 px-3 border">
                                {calculateCompletionPercentage(d)}%
                              </td>
                              <td className="py-2 px-3 border">
                                <button
                                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded ${
                                    expandedId === d._id
                                      ? "bg-red-500"
                                      : "bg-blue-500"
                                  }`}
                                  onClick={() =>
                                    setExpandedId(
                                      expandedId === d._id ? null : d._id
                                    )
                                  }
                                >
                                  {expandedId === d._id ? "Close" : "View"}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <p className="mt-4 font-bold text-gray-800">
                        Total Respondents: {portfolioData.length}
                      </p>
                      {expandedId &&
                        portfolioData.find((d) => d._id === expandedId) && (
                          <div className="mt-4">
                            {renderDetails(
                              portfolioData.find((d) => d._id === expandedId)!
                            )}
                          </div>
                        )}
                    </div>
                  ),
                };
              }),
            ]}
          />
        </>
      ),
    };
  });

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10 px-4">
      <h1 className="text-4xl font-extrabold mb-6 text-gray-800 text-center">
        MX Form Results
      </h1>
      {data.length === 0 ? (
        <p className="text-gray-600">No results found.</p>
      ) : (
        <div className="w-full max-w-5xl">
          {isMobile ? (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Select LC
              </label>
              <select
                className="w-full p-2 border border-gray-300 rounded-md"
                onChange={(e) => {
                  setExpandedId(null);
                  setSelectedLC(e.target.value);
                }}
              >
                <option value="">Select LC</option>
                {lcOptions.map((lcName) => (
                  <option key={lcName} value={lcName}>
                    {lcName}
                  </option>
                ))}
              </select>
              {selectedLC && (
                <select
                  className="w-full p-2 mt-2 border border-gray-300 rounded-md"
                  onChange={(e) => {
                    setExpandedId(null);
                    setSelectedPortfolio(e.target.value);
                  }}
                >
                  <option value="">Select Portfolio</option>
                  {portfolioOptions.map((portfolio) => (
                    <option key={portfolio} value={portfolio}>
                      {portfolio}
                    </option>
                  ))}
                </select>
              )}
              {selectedLC && selectedPortfolio && (
                <div className="w-full p-6 bg-white rounded-lg shadow-md mt-4">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 text-center">
                    {selectedPortfolio}
                  </h2>
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="py-2 px-3 bg-gray-200 text-gray-600 font-bold">
                          Name
                        </th>
                        <th className="py-2 px-3 bg-gray-200 text-gray-600 font-bold">
                          Completion %
                        </th>
                        <th className="py-2 px-3 bg-gray-200 text-gray-600 font-bold">
                          View Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter(
                          (d) =>
                            d.generalDetail.lcName === selectedLC &&
                            d.generalDetail.portfolio === selectedPortfolio
                        )
                        .map((d) => (
                          <tr key={d._id}>
                            <td className="py-2 px-3 border">{`${d.generalDetail.firstName} ${d.generalDetail.lastName}`}</td>
                            <td className="py-2 px-3 border">
                              {calculateCompletionPercentage(d)}%
                            </td>
                            <td className="py-2 px-3 border">
                              <button
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded ${
                                  expandedId === d._id
                                    ? "bg-red-500"
                                    : "bg-blue-500"
                                }`}
                                onClick={() =>
                                  setExpandedId(
                                    expandedId === d._id ? null : d._id
                                  )
                                }
                              >
                                {expandedId === d._id ? "Close" : "View"}
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <p className="mt-4 font-bold text-gray-800">
                    Total Respondents:{" "}
                    {
                      data.filter(
                        (d) =>
                          d.generalDetail.lcName === selectedLC &&
                          d.generalDetail.portfolio === selectedPortfolio
                      ).length
                    }
                  </p>
                  {expandedId && data.find((d) => d._id === expandedId) && (
                    <div className="mt-4">
                      {renderDetails(data.find((d) => d._id === expandedId)!)}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <Tabs
              tabs={[
                {
                  title: "National Summary",
                  value: "national-overall-summary",
                  content: renderNationalSummary(),
                },
                ...lcTabs,
              ]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MXResults;
